@media (max-width: 1366px) {
  .chat-preview {
    height: 400px;
  }
}

.chat-preview {
  background-color: white;
  border: 1px solid #eee;
  width: 300px;
  height: 500px;
  position: absolute;
  right: 0px;
  bottom: 75px;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px;
  z-index: 5;
}
.chat-preview .chat-header-options {
  align-self: center;
}

.chat-preview .chat-body {
  display: flex;
  height: calc(100% - 98px);
  flex-direction: column;
  overflow-y: auto;
}

.chat-preview .chat-footer {
  width: 100%;
  position: absolute;
  font-size: 16px;
  bottom: 0;
}

.chat-preview .chat-footer .input-group-text {
  padding: 15px;
  background-color: white;
}

.chat-header {
  border-bottom: 1px solid;
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-wrapper-row {
  display: flex;
  align-items: center;
  padding: 10px;
}
.message-wrapper-decisions {
  margin-left: 35px;
}

.message-wrapper-user {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
}
.message-wrapper-user .message {
  border-radius: 0.75rem 0.75rem 0 0.75rem;
  background-color: aliceblue;
}
.message {
  width: 100% !important;
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  padding: 0.75em;
  background-color: #eee;
  display: block;
  border: none;
}

.send-message {
  display: flex;
  justify-content: flex-end;
}

.receive-message > img {
  height: 30px;
}

.send-message-box {
  background: #5086e9;
  color: white;
}
.receive-message-box {
  background: #f0f0f0;
}

.chat-preview-button {
  height: 60px;
  width: 60px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.h-5rem {
  height: 5rem;
}

#typing-indicator {
  /* background-color: #f0f0f0; */
  will-change: transform;
  /* width: 72px; */
  /* border-radius: 50px; */
  /* padding: 13px; */
  display: table;
  /* margin-left: 50px; */
  /* margin-bottom: 10px; */
  position: relative;
  animation: 2.5s bulge infinite ease-out;
}

/* #typing-indicator::before,
#typing-indicator::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: -2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f0f0f0;
} */

#typing-indicator::after {
  height: 10px;
  width: 10px;
  left: -8px;
  bottom: -8px;
}

#typing-indicator span {
  height: 11px;
  width: 11px;
  float: left;
  margin: 0px 2px;
  background-color: #5086e9;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
#typing-indicator span:nth-of-type(1) {
  animation: 2s blink infinite 0.66s;
}

#typing-indicator span:nth-of-type(2) {
  animation: 2s blink infinite 1.333s;
}

#typing-indicator span:nth-of-type(3) {
  animation: 2s blink infinite 1.9999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

.image img {
  width: 100%;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.col-2 {
  width: 16.66666%;
}
.col-4 {
  width: 33.3333333%;
}
.col-6 {
  width: 50%;
}
.col-8 {
  width: 66.66666%;
}
.col-12-2 {
  width: 83.3332%;
}
.col-12{
  width: 100%;
}
.col {
  display: flex;
  flex-direction: row;
  float: left;
}

input[type="checkbox"]{
  width: 22px;
  height: 22px;
}

